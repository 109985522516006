<template lang="pug">
  .filter-group
  
    v-select(:taggable="false", :multiple="multiple" v-model="inner", :placeholder="placeholder", label="web_entity", :close-on-select="false", :options="options", :filterable="false", @search="onSearch")
        
        template(slot="no-options") Type to search
        template(slot="option" slot-scope="option")

          //template(v-if="option.name == 'add'")
          //  i.span Add new company
          template()
            .span
              .media
                Logo(:web_entity="option.web_entity", :name="option.name", :size="15").mr-1
                .media-body
                  | {{ option.name.substring(0,40) }} &dash; {{ option.web_entity }}
        template(slot="selected-option" slot-scope="option")
            .selected
                | {{ option.web_entity }}

</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Logo from '@/components/Logo'
export default {
  name: 'CompanySelect',
  
  props: {
    "value":  {
      default: null
    }, 

    "placeholder":  {
      default: ""
    }, 

    "multiple": {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
        uid: null,
        options: [],
        prev_request: null,
        inner: null
        
    }
  },

  mounted: function() {
     this.inner = this.value;
     this.uid = this._uid;
     
  },

  watch: {
    inner: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      }
    }
  },
  
  methods: {
    
   onSearch(search, loading) {
      
      this.$emit("search", search);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
      
      
      if(search) {
        loading(true);
     
          Vue.http.get(`${Config.config.base_host}/organizations/search/autocomplete?q=${escape(search)}`).then(res => {
          vm.options = res.body.rows.filter(x => x.web_entity);

          //vm.options.unshift({name: "add"})
          loading(false);
        });
      } else {
        vm.options = [];
      }

    }, 50)
  },

  components: {
    Logo
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
