<template lang="pug">

  .overview
    .row.mb-5
      .col-3
        b-link(href="/news/") 
          .fas.fa-arrow-left.mr-3
          | Back

        div.mt-5(v-if="sections")
          div(v-if="sections['foundings'] && sections['foundings'].length").mt-3 
           
            b.text-muted New companies
            
            div(v-for="entry in sections['foundings']")
              
              .media.mt-0().mt-3
                
                  a(:href="entry.org_url")
                      div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                  .media-body()
                    a(:href="entry.org_url").small {{entry.org_name}} 
                    .small.truncate-2.text-muted.small {{entry.short_description}}
          
          div(v-if="sections['funding_rounds'] && sections['funding_rounds'].length").mt-3 
            hr.mt-4
            b.text-muted New funding rounds
            div(v-for="entry in sections['funding_rounds']")
              
              .media.mt-0().mt-3
                
                  a(:href="entry.org_url")
                      div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                  .media-body()
                    a(:href="entry.org_url").small {{entry.org_name}} - {{ucfirst(entry.investment_type.replace("_", " "))}} - {{entry.value}}
                    .small.truncate-2.text-muted.small {{entry.short_description}}

          div(v-if="sections['aquisitions'] && sections['aquisitions'].length").mt-3 
            hr
            b.text-muted Acquistions
            div(v-for="entry in sections['aquisitions']")
              .media.mt-0().mt-3
                
                  a(:href="entry.org_url")
                      div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                  .media-body()
                    a(:href="entry.org_url").small {{entry.org_name}} 
                    .small.truncate-2.text-muted.small {{entry.short_description}}
      .col-1
      .col-8
        .text-center
            b-spinner(v-if="news == null")
        div(v-if="selectedTopic && newsletter")
          .row
            .col
              h1 {{selectedTopic.name}}
            .col
              .float-right
                b-select(v-model="sortBy", :options="[{'value': 7, 'text': 'Last week'}, {'value': 31, 'text': 'Last month'}, {'value': 90, 'text': 'Last quarter'}]", size="sm" @change="getTopic()").ml-1
          
          
                      
          div(id="news-list-group", v-if="news && news.entries.length > 0").mt-5
                 
            div(v-for="entry in news.entries").h-mouseover-parent

                //.float-right
                  
                  b-btn(variant="link", v-if="in_newsletter(entry)", @click="remove_newsletter(entry)")
                    <i class="fa fa-bookmark"></i>
                  b-btn(variant="link", v-if="!in_newsletter(entry)", @click="add_newsletter(entry)").h-mouseover-show
                    <i class="fas fa-ellipsis-h"></i>
                  div(style="width: 40px;" v-if="!in_newsletter(entry)").h-mouseover-hide
                
          
                .media.mt-0(v-if="entry.doc_type == 'FeedEntryNews'").mt-3
                    
                    a(:href="entry.link")
                        div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.source + ') center 0% / cover no-repeat;'").mr-2

                    .media-body()
                        a(:href="entry.link", target="_blank")
                          
                            .truncate-2() {{entry.title}}
                          
                            //template(v-if="entry.source_data && entry.source_data.highlights")
                              .text-muted.small.desc(v-for="e in entry.source_data.highlights.full_text" v-html="e", v-show="desc_show")
                      
                            .text-muted.small() {{entry.created_at | utcFrom}} &dash; {{entry.source}} &dash; {{Math.round(entry.source_data.score[2]*100)}}
                            
                            span.mr-1(v-show="desc_show")
                              .text-muted.small(v-if="entry.categories && entry.categories['investment'] > 0.5") Investment
                              .text-muted.small(v-if="entry.categories && entry.categories['explainer'] > 0.5") Explainer
                              .text-muted.small(v-if="entry.categories && entry.categories['launch'] > 0.5") Launch
                              //.text-muted.small(v-if="entry.categories && entry.categories['study'] > 0.5") Study
                              //.text-muted.small(v-if="entry.categories && entry.categories['operations'] > 0.5") Operating metric
                          
         
    

   

        
</template>

<script>
import Vue from 'vue';
import Config from '@/config'

import ProjectCard from '@/components/ProjectCard.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'NewsDetail',
  props: {
 
    "show_industries": {
      type: Boolean,
      default: true
    }, 
    "project": {
      type: Object,
      default: null
    }
  },


  data: function () {
      return {
        filters: {
          "explainer": true,
          "launch": true,
          //"management": true,
          "investment": true,
          //"acquistions": true,
          //"research": true,
          //"operations": true,
          "partner": true,
          "others": true
        },
        desc_show: true,
        news: null,
        sections: null,
        info_show: true,
        industries: null,
        selectedIndustry: null,
        topics: null,

        sortBy: 7,
        sortByTop: 10
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getNewsletter();
    this.getTopic();
    

    if (localStorage.desc_show) {
      this.desc_show = localStorage.desc_show == "true";
    }
    if (localStorage.info_show) {
      this.info_show = localStorage.info_show == "true";
    }
    if (localStorage.sortBy) {
      this.sortBy = localStorage.sortBy;
    }
     if (localStorage.sortByTop) {
      this.sortByTop = localStorage.sortByTop;
    }
     if (localStorage.filters) {
      this.filters = localStorage.filters;
    }

    if(this.$route.params.topic_id) {
        this.$store.dispatch("loadTopic", this.$route.params.topic_id);
    }
    
  },

  watch: {

    '$route.params.topic_id': function () {
      if(this.$route.params.topic_id) {
        this.$store.dispatch("loadTopic", this.$route.params.topic_id);
      }
      window.dispatchEvent(new Event('resize'));
    },

    'desc_show': function(val) {
      localStorage.desc_show = val;
    },
     'info_show': function(val) {
      localStorage.info_show = val;
    },
     'sortBy': function(val) {
      localStorage.sortBy = val;
    },

    'sortByTop': function(val) {
      localStorage.sortByTop = val;
    },

    'filters': function(val) {
      localStorage.filters = val;
    },
    
    
    'results': function () {
      this.results.forEach(it => {
        console.log(it);
      });
      
    },
    
  },

  computed: {
       
        ...mapFields([
            'topics.results',
            'topics.trends',
            'sidebar_project.selected_item',
            'selectedTopic'
        ]),
  },

  
  methods: {

     ucfirst(text) {
      if (!text) return text;
      return text[0].toUpperCase() + text.slice(1);
    },

    toggleFilter(name) {
      this.filters[name] = !this.filters[name];
      localStorage.filters = this.filters;
      this.getFollowTopic();
    },

    in_newsletter(entry) {
     
      return this.newsletter.items.map((it)=>{return it.link}).indexOf(entry.link) > -1;
    },

    remove_newsletter(entry) {
      this.newsletter.items = this.newsletter.items.filter(it => it.link != entry.link);
      this.save_newsletter(this.newsletter);
      this.$forceUpdate();
    },

    add_newsletter(entry) {
      console.log(entry);
      this.newsletter.items.push(entry);
      this.save_newsletter(this.newsletter);
      this.$forceUpdate();
    },

    save_newsletter(nl) {

      if(!nl) {
        nl = {"_id":  "newsletter:" + this.$store.state.user.id};
      }

      Vue.http.put(`${Config.config.base_host}/newsletters/${nl._id}`, nl).then((res) => {
           
            this.newsletter = res.body;
      });

    },

    getNewsletter(nl_id = null) {
        if(!nl_id) nl_id = "newsletter:" + this.$store.state.user.id;
        this.$store.dispatch('start_loading');
        Vue.http.get(`${Config.config.base_host}/newsletters/${nl_id}`).then((res) => {
            this.newsletter = res.body;
            this.$store.dispatch('stop_loading');
      });
    },
    


    heatMapColorforValue(percentage, hue0 = 120, hue1 = 0) {
      var hue = ((1 - percentage) * (hue1 - hue0)) + hue0;
      return 'hsl(' + hue + ', 100%, 50%, 0.2)';
    },

    

      getTrends(topic_id) {
        this.$store.dispatch("start_loading");
         Vue.http.get(Config.config.base_host+'/topics_v1/media_attention/'+topic_id).then(resp => {
            this.trends[topic_id] = resp.body;
            this.$forceUpdate();
            this.$store.dispatch("stop_loading");
        }); 
      },
  

      

       getTopic() {
      
          this.$store.dispatch('start_loading');
          
          Vue.http.post(`${Config.config.base_host}/topics_v1/news_feed`, {"type_filter": Object.keys(this.filters).map((key) => {if(this.filters[key]) return key}).filter(x => x != null) ,"days": this.sortBy ,"q":[], "limit": 1000, "sortBy":"_score","project_ids":null, "topic_ids":[this.$route.params.topic_id], "page":1}).then(res2 => {
            this.news = res2.body;
            this.$store.dispatch('stop_loading');
          }); 

          Vue.http.post(`${Config.config.base_host}/topics_v1/sections`, {"type_filter": Object.keys(this.filters).map((key) => {if(this.filters[key]) return key}).filter(x => x != null) ,"days": this.sortBy ,"q":[],"sortBy":"_score","project_ids":null, "topic_ids":[this.$route.params.topic_id], "page":1}).then(res2 => {
              this.sections = res2.body;
          });

      

      }


  },
  components: {
      ProjectCard
  }
}

</script>
<style lang="scss">
  .desc { 
      em {
        font-style: normal;
        font-weight: bold !important;
        background-color: rgba(255, 255, 0, 0.226) !important;
      }
    }
</style>
<style lang="scss" scoped>
   
    .score {
      display: inline-block;
      font-size: 1em;
      padding: 1px;
      min-width: 100px;
      margin: 1px;
    }

    a {
        text-decoration: none !important;
    }
</style>