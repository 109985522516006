<template lang="pug">
  
  .home
   
    .row.mt-1
      .col-md-2
      //.col-md-3
        b-list-group()
            b-list-group-item(v-for="entry in this.nl.items").small {{entry.title}}
        
    
      .col-md-8()
        .row
            .col-12
                b-btn(variant="secondary", size="sm", @click="save_newsletter(nl)").mr-1 Save
                b-btn(variant="link", size="sm", @click="reset()").mr-1 Delete all bookmarks
                
                .float-right
                    b-btn(variant="secondary", size="sm", @click="view_newsletter(nl)").mr-1 Preview
                    b-btn(variant="primary", size="sm", @click="download_newsletter(nl)") Download

                    //b-dropdown(size="sm" variant="link" no-caret, right).options.option-dropdown
                      template(v-slot:button-content)
                        i.fas.fa-ellipsis-h.text-muted     
                      b-dropdown-item(href="#", @click="showModal($store.getters.project)")#edit-project Delete all bookmarks
        

        .row
            .col-12
              
              b-overlay(:show="$store.state.loading")
                b-form()
                  b-card().mt-3
                        b-form-group(label="title", :disabled="head_disabled")
                          b-input(v-model="nl.subject")
                        b-form-group(label="Intro", :disabled="head_disabled")
                          b-textarea(v-model="nl.intro")
                        
                        b-form-group(label="Newsletter Sections")
                          v-select(id="stages"
                                      :disabled="head_disabled"
                                      :options="[]",
                                      :multiple="true"
                                      :taggable="true"
                                      :close-on-select="false"
                                      :no-drop="true"
                                      placeholder="Enter a stage name and press enter"
                                      :select-on-tab="true"
                                      v-model="nl.sections")
                       
              
           
        .row
          .col-12
              .mt-3
                .float-right
                    b-btn(variant="secondary", size="sm", @click="add_entry()").mr-1 New entry
        .row
          .col-12

              b-card(v-for="section in nl.sections", :title="section").mt-3
               
               
                    div(v-if="nl.items && entry.section == section" v-for="entry in nl.items").mt-3.mb-3
                      
                      hr().mt-2.mb-4

                      .float-right
                        b-btn(variant="outline-secondary", size="sm", @click="edit_mode(entry)", v-if="!edit_modes[entry._id]").edit-1
                          <i class="fas fa-pen"></i> Edit
                        
                        b-btn(variant="secondary", size="sm", @click="edit_mode(entry)", v-if="edit_modes[entry._id]").edit-2
                          <i class="fas fa-pen"></i> Edit

                        b-btn(variant="link", size="sm", v-if="in_newsletter(entry)", @click="remove_newsletter(entry)").remove.text-muted
                          <i class="fas fa-trash"></i>
                      
                      
                      .media.mt-0()
                        
                        .media-body(style="max-width: 800px")
                          
                          a(:href="entry.link", target="_blank") 
                            h6.mb-1 {{entry.title}}
                          
                          .text-muted.small(v-if="entry.text") {{entry.text}}
                          .text-muted.small(v-if="entry.investor_list") 
                            label.mr-1 Investors 
                            .d-inline {{entry.investor_list.join(", ")}}
                          
                          .text-muted.small {{entry.created_at | utcFrom}} &mdash; <a :href="entry.link" target="_blank">Source</a>

                    
                 
                    
                      div(v-if="edit_modes[entry._id]")
                          .form-row.mt-4
                            .col-6
                              b-form()
                                b-form-datepicker(v-model="entry.created_at", size="sm", placeholder="Published")
                                
                            .col-6
                                b-select(:options="nl.sections", size="sm", v-model="entry.section").input-sections.small.d-block
                              
                          .form-row.mt-1
                            .col-12
                                b-input(v-model="entry.title", size="sm", placeholder="Title").input-title.mt-1
                                b-textarea(v-model="entry.text", size="sm", placeholder="Company description or text").input-text.mt-2

                          .form-row.mt-3
                            .col-6
                              b-form-group(label="Company Urls") 
                                v-select(:taggable="true", :multiple="true", placeholder="Companies" v-model="entry.company_domains", :filterable="false").input-domains.small
                            .col-6
                              b-form-group(label="Investors") 
                                v-select(:taggable="true", :multiple="true", placeholder="Investors" v-model="entry.investor_list", :filterable="false").input-list.small
                          
                          .form-row.mt-1
                            .col-12
                              b-form-group(label="News Source") 
                                b-input(v-model="entry.link", size="sm", placeholder="News source URL").input-news
                          
                          .form-row.mt-2
                            .col-12
                              .float-right
                                b-btn(variant="primary", size="sm", @click="save_newsletter(nl); edit_mode(entry);").input-save.mr-1 Save
                                
                                   
                      
                               
                                
</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'

import NewsFilter from '@/views/news/NewsFilter'
import OrgView from '@/components/OrgView.vue'
import CompanySelect from '@/components/CompanySelect.vue'


import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'home',
  
  data: function () {
    return {
        feed: [],
        sort_options: [{"text": "Date", "value": "pub_date"}, {"text": "Relevance", "value": "_score"}],
        nl: {title: "", intro: "", sections: []},
        head_disabled: false,
        current_entry: {},
        edit_modes: {}
    }

  },

  mounted: function() {
    console.log(Config);
    this.host = Config.config.base_host;
    this.getNewsletter()
  },

   computed: {
        
        ...mapFields([
            'news.filter.q',
            'news.filter.sortBy',
        ]),

  },

   beforeRouteLeave (to, from , next) {
     this.save_newsletter(this.nl);
     next();
    },



  methods: {

    edit_mode(entry) {
        let old = this.edit_modes[entry._id];
        this.nl.items.forEach((x) => { 
          this.edit_modes[x._id] = false;
        });
        this.edit_modes[entry._id] = !old;
        this.$forceUpdate();
    },

    in_newsletter(entry) {
      
      return this.nl.items.map((it)=>{return it.link}).indexOf(entry.link) > -1;
    },

    remove_newsletter(entry) {
      this.nl.items = this.nl.items.filter(it => it.link != entry.link);
      this.save_newsletter(this.nl);
    },

    add_entry() {
      let entry = {section: "Others", "_id": this.nl.items.length};
      this.nl.items.unshift(entry);
      this.edit_mode(entry);
    },

    save_newsletter(nl) {

      if(!nl) {
        nl = {"_id":  "newsletter:" + this.$store.state.user.id};
      }
      this.$store.dispatch('start_loading');
      Vue.http.put(`${Config.config.base_host}/newsletters/${nl._id}`, nl).then((res) => {
          this.nl = res.body;
          if(!this.nl.sections.length) this.nl.sections = this.$store.state.instance_config.default_newsletter_sections;
           this.$store.dispatch('stop_loading');
      });

    },

    getNewsletter(nl_id = null) {
        if(!nl_id) nl_id = "newsletter:" + this.$store.state.user.id;
        this.$store.dispatch('start_loading');
        Vue.http.get(`${Config.config.base_host}/newsletters/${nl_id}`).then((res) => {
            this.nl = res.body;
            
            this.nl.items.forEach((x,i) => { 
              console.log(x);
              if(!x.section) x.section = "Others";
              x._id = i;
              this.edit_modes[x._id] = false;
            });

            if(!this.nl.sections.length) { 
              this.nl.sections = this.$store.state.instance_config.default_newsletter_sections;
            }
            if(this.nl.sections.indexOf("Others") == -1) {
              this.nl.sections.unshift("Others");
            }
            this.$store.dispatch('stop_loading');
      });
    },


    view_newsletter(nl) {
      
      Vue.http.put(`${Config.config.base_host}/newsletters/${nl._id}`, nl).then((res) => {
        this.nl = res.body;
        Vue.http.get(`${Config.config.base_host}/newsletters/${nl._id}/html`).then(resp=>{
            var wnd = window.open("about:blank", "", "_blank");
            wnd.document.write(resp.body);
        });
        this.$store.dispatch('stop_loading');
      });

      
    
    },

    reset() {

        this.$bvModal.msgBoxConfirm("Are you sure? All bookmarks will be deleted.", {buttonSize: 'sm'}).then((value) => {
            console.log(value);

            if(value) {
                this.$store.dispatch("start_loading");
                this.nl.items = [];
                this.save_newsletter(this.nl);

            }
            
      
        });

    },


     download_newsletter(nl) {
      
      Vue.http.put(`${Config.config.base_host}/newsletters/${nl._id}`, nl).then((res) => {
        this.nl = res.body;
        Vue.http.get(`${Config.config.base_host}/newsletters/${nl._id}/html`).then(resp=>{
             this.downloadFile("newslwetter.html", resp.body);
        });
        this.$store.dispatch('stop_loading');
      });

      
    
    },

   


    downloadFile(filename, dataValue) {
      window.URL = window.webkitURL || window.URL;
      window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder;

      var a = document.createElement('a');
      a.download =  filename;
      var bb = null;
      if (window.BlobBuilder == undefined) {
          bb = new Blob([dataValue], { 'type': "text/html" });
          a.href = window.URL.createObjectURL(bb);
      }
      else {
          bb = new window.BlobBuilder();
          bb.append(dataValue);
          a.href = window.URL.createObjectURL(bb.getBlob( "text/html"));
      }

      a.textContent = '';

      a.dataset.downloadurl = [ "text/html", a.download, a.href].join(':');
      a.draggable = true; // Don't really need, but good practice.
      a.classList.add('dragout');

      document.body.appendChild(a);

     

      a.click();
    }




   

  },
  components: {
    OrgView,
    NewsFilter,
    CompanySelect
  }
}

</script>
<style lang="scss" scoped>


</style>