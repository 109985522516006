<template lang="pug">
  .search-filter
    b-form()
        FilterGroup(title="Date range" :collpased="true").mt-3
          b-select(:options="[{ value: 7, text: 'Last week' }, { value: 31, text: 'Last month' }, { value: 30*3, text: 'Last quarter' }, { value: 365, text: 'Last year' }]", size="sm" v-model="days")
          
        //FilterGroup(title="Keywords" :collpased="true").mt-3
          .small
             v-select(id="keyword"
                      :options="[]",
                      :multiple="true"
                      :taggable="true"
                      :close-on-select="false"
                      :no-drop="true"
                      :select-on-tab="true"
                      v-model="q", placeholder="Enter term and press enter")

        FilterGroup(title="Categories" :collpased="true").mt-3
            b-input-group()
              b-form-checkbox-group(:options="type_filter_options", v-model="type_filter")#type-filter

        FilterGroup(title="Projects / Topics" :collpased="true", v-if="show_project_select").mt-3
            .small
             ProjectStageSelect(v-model="project_ids", :create_option="false", :multiple="true", placeholder="Select project")
             TopicSelect(v-model="topic_ids", :create_option="false", :multiple="true", :edit="true", topic_type="topic", :need_query="true", :show_follow_only="true").mt-1
             
             
        //FilterGroup(title="Projects" :collpased="true", v-if="show_project_select").mt-3
        //    .small
        //      ProjectStageSelect(v-model="project_id", :create_option="false", :multiple="true")

              //.mt-2(v-if="project_id && project_id.length")
                ProjectStageSelect(:create_option="false", :multiple="true")

        //FilterGroup(title="Topics" :collpased="true", v-if="show_project_select").mt-1
            .small
              TaxonomySelect(topic_type="topic", v-model="topic_id", :create_option="false", :multiple="true")

        //FilterGroup(title="Categories" :collpased="true").mt-3

              b-select(:options="['msdjjsd']", id="category1", v-model="categorie")

        .mt-3
            b-button(size="sm", variant="outline-secondary", @click="getData()").form-control Search

</template>

<script>
// @ is an alias to /src

import Config from '@/config'

import { mapFields } from 'vuex-map-fields';
import TopicSelect from '@/components/TopicSelect'
import FilterGroup from '@/components/FilterGroup'
import TagSelect from '@/components/TagSelect'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import TaxonomySelect from '@/components/TaxonomySelect'
export default {
  
  name: 'ProjectFilter',

 props: {
    fix_project_id: {
      default: null
    },
    show_project_select: {
      default: () => {
        return true;
      }
    }
  },

  data: function () {
    return {
      selected: 'No similarity search',
      type_filter_options:[{"text": "All News", "value": "news"},  {"text": "Partner", "value": "news_partner"},  {"text": "Investments", "value": "investments"}]
    }
  },

  mounted: function() {
  
     this.host = Config.config.base_host;
  
  },

  computed: {

        ...mapFields({
            q: 'news.filter.q',
            days: 'news.filter.days',
            project_ids: 'news.filter.project_ids',
            topic_ids: 'news.filter.topic_ids',
            type_filter: 'news.filter.type_filter',
            page: 'news.filter.page'
        }),
  },
  

  methods: {
    
    getData() {
        if(this.fix_project_id) this.project_id = this.fix_project_id;
        this.$emit("reset");
        this.page = 1;

        setTimeout(()=>{
          this.$store.getters.news.results = []; 
          this.$store.dispatch("getNewsFeed");
          this.page++; 
        }, 500);
      
    },

  },
  components: {
    FilterGroup,
    TagSelect,
    ProjectStageSelect,
    TaxonomySelect,
    TopicSelect
  }
}

</script>
<style lang="scss" scoped>
/*.search-filter {
    position: fixed;
    margin-top: -50px;
    z-index: 100000;
}*/
</style>
